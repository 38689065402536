import { captureException } from '@sentry/nextjs'
import { x } from '@xstyled/styled-components'
import { FormikHelpers, useFormikContext } from 'formik'
import React from 'react'

import { Images, ROUTES } from '@/constants'
import { H2, Link, OrbitLogo, Separator, Text, toast } from '@/kit'
import { useQueryParam } from '@/shared/hooks'
import { FormikForm, GoogleLoginButton } from '@/shared/ui'

import { LoginSchema } from './Login.schema'
import * as S from './LoginForm.styled'

interface LoginFormProps {
  onSubmit: (values: LoginSchema.Values, actions: FormikHelpers<LoginSchema.Values>) => Promise<void>
}

export const LoginForm = ({ onSubmit }: LoginFormProps) => {
  const [loading, setLoading] = React.useState(false)

  const handleSubmit = React.useCallback(
    async (values: LoginSchema.Values, actions: FormikHelpers<LoginSchema.Values>) => {
      try {
        setLoading(true)
        await onSubmit(values, actions)
      } finally {
        setLoading(false)
      }
    },
    [onSubmit],
  )

  return (
    <S.$LoginFlowContainer>
      <S.$TopBar>
        <x.div display="flex" gap="8px" alignItems="center">
          <OrbitLogo size={30} />
          <Text color="grey.100" fontSize="lg" fontWeight="semibold">
            Orbit
          </Text>
        </x.div>
      </S.$TopBar>
      <S.$LoginLeftContainer>
        <x.div display="flex" flexDirection="row" alignItems="center" justifyContent="center" w={1} mb={25}>
          <H2 w={1} textAlign="center" mr="7px">
            Log in to Orbit
          </H2>
        </x.div>
        <GoogleLoginButton />
        <x.div position="relative" w={1} mb={20}>
          <x.div position="relative" bg="white" w="32px" h="20px" mx="auto" zIndex={2}>
            <Text color="grey.48" textAlign="center">
              or
            </Text>
          </x.div>
          <Separator position="absolute" left="0" right="0" top="10px" />
        </x.div>
        <FormikForm<LoginSchema.Values>
          validationSchema={LoginSchema.validationSchema}
          initialValues={LoginSchema.defaultValues}
          onSubmit={handleSubmit}
        >
          <FormBody loading={loading} />
        </FormikForm>
      </S.$LoginLeftContainer>
      <S.$LoginRightContainer>
        <S.$MainImage src={Images.LoginBackground} />
      </S.$LoginRightContainer>
    </S.$LoginFlowContainer>
  )
}

const FormBody = ({ loading }: { loading: boolean }) => {
  const loginError = useQueryParam('error')
  const { values } = useFormikContext<LoginSchema.Values>()

  const email = values?.email

  React.useEffect(() => {
    if (!loginError) return

    if (loginError === 'accountNotFound') {
      toast.error("We couldn't find an account with that email.")
      captureException(new Error(`Login error: account with email ${email} not found`), { user: { email } })
    }
  }, [loginError, email])

  return (
    <>
      <FormikForm.TextField name="email" placeholder="Email" autoComplete="username" xss={{ mb: 15 }} />
      <FormikForm.TextField
        name="password"
        type="password"
        placeholder="Password"
        autoComplete="current-password"
        xss={{ mb: 30 }}
      />
      <FormikForm.SubmitButton maxWidth="unset" mb={30} w={1} loading={loading} text="Log in" />

      <Text color="grey.100" fontSize="sm" textAlign="center" mb={15}>
        <Link
          href={ROUTES.auth.forgotPassword}
          variant="primary"
          display="inline-block"
          justifyContent="flex-start"
          fontWeight="semibold"
          textDecoration="underline"
        >
          Forgot password?
        </Link>
      </Text>
    </>
  )
}
